
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { showAlert } from '@/core/plugins/Notifications';
import { CreateReference } from '@/wms/application/catalogs/references/create/CreateReference';
import { GroupReferenceFindAll } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAll';
import { CreateGroupReference } from '@/wms/application/category/groupReferences/create/CreateGroupReference';
import { GroupReferenceFindAllTree } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAllTree';
import { GroupReferenceFindById } from '@/wms/application/category/groupReferences/search/GroupReferenceFindById';
import { GroupReferenceDelete } from '@/wms/application/category/delete/GroupReferenceDelete';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { EntityFindAll } from '@/settings/application/uses_cases/entity/search/EntityFindAll';
import { GroupReferenceUpdate } from '@/wms/application/category/groupReferences/update/GroupReferenceUpdate';
import { GroupReferenceValidateCode } from '@/wms/application/category/groupReferences/validateCode/GroupReferenceValidateCode';

@Component({
  name: 'SettingReferences',
  components: { CustomTable }
})
export default class Login extends Vue {
  @Inject(TYPES.CREATE_GROUP_REFERENCE)
  readonly createGroupReference!: CreateGroupReference;
  @Inject(TYPES.VALIDATE_GROUP_REFERENCE_CODE)
  readonly validateCodeGroupReferece!: GroupReferenceValidateCode;
  @Inject(TYPES.UPDATE_GROUP_REFERENCE)
  readonly updateGroupReference!: GroupReferenceUpdate;
  @Inject(TYPES.FINDALL_GROUP_REFERENCE)
  readonly groupReferenceFindAll!: GroupReferenceFindAll;
  @Inject(TYPES.DELETE_GROUP_REFERENCE)
  readonly groupReferenceDelete!: GroupReferenceDelete;
  @Inject(TYPES.CREATE_REFERENCE)
  readonly createReference!: CreateReference;
  @Inject(TYPES.FINDALL_GROUP_TREE_REFERENCE)
  readonly groupReferenceFindAllTree!: GroupReferenceFindAllTree;
  @Inject(TYPES.FINDBYID_GROUP_REFERENCE)
  readonly findByIdGroupReference!: GroupReferenceFindById;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.API_ENTITY_FIND_ALL_PAGE)
  readonly findSuppliers!: EntityFindAll;

  $refs!: {
    childComponent: HTMLFormElement;
    observer: HTMLFormElement;
    suppliersModal: HTMLFormElement;
  };
  //DATA
  componentKey = 1;
  widthResize = 0;
  isTouched = false;
  tabIndex = 0;
  parents: any = [
    {
      subGroup: [
        {
          subGroup: [{}]
        }
      ]
    }
  ];
  isShow = false;
  isvalidDelete = false;
  optionsLevel = [
    { text: this.$t('general.firstLevel'), value: 1 },
    { text: this.$t('general.secondLevel'), value: 2 },
    { text: this.$t('general.supplierLevel'), value: 3 },
    { text: this.$t('general.brandLevel'), value: 4 }
  ];
  isDisabledRadio = false;
  title = '';
  form: any = {
    //GROUP_REFERENCE
    newCode: '',
    level: null,
    description: '',
    shortName: '',
    parent: {},
    stateDisable: true,
    isLiquor: false,
    subGroup: '',
    createAt: '',
    updateAt: '',
    supplier: null,
    isServices: false
  };
  enableSupplier = false;
  suppliers: any = [];
  isLoading = false;
  isEditing = false;
  editOnlyDescription = false;
  lastEditing: any = null;

  actions = {
    edit: this.setValue
  };

  //GETTERS

  get fields() {
    return [
      {
        key: 'code',
        label: this.$t('general.code')
      },
      {
        key: 'prefix',
        label: this.$t('general.prefix')
      },
      {
        key: 'fullName',
        label: this.$t('general.name')
      },
      {
        key: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }
  //METHODS
  created() {
    this.parentsquery();
    this.setTitle(this.$t('general.createGroup'));
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async validateCodeCategory(code: string) {
    try {
      this.isLoading = true;
      const validateCode = await this.validateCodeGroupReferece.execute(code);
      if (validateCode) {
        showAlert(
          'warning',
          this.$t('generalmessage.ERROR_REGISTER_TITLE'),
          `${this.$t('generalmessage.ERROR_EXIST_CODE', { code })}`,
          ''
        );

        this.form.newCode = '';
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  validateLevel(masterCategory: any, parent: any, currentLevel: number) {
    this.cleanGroup();

    if (this.isEditing || this.lastEditing) {
      this.clearForm();
      this.lastEditing.isEditing = false;
    }

    let hasExecuteSetParent = false;
    this.form.level = masterCategory.level;
    this.enableSupplier = false;

    switch (currentLevel) {
      case 1:
        this.setTitle(this.$t('general.createSubGroup'));
        if (masterCategory.level > 1) {
          hasExecuteSetParent = true;
        }
        break;

      case 2:
        this.setTitle(this.$t('general.createSupplierGroup'));
        if (masterCategory.level > 2) {
          hasExecuteSetParent = true;
          this.enableSupplier = true;
        }
        break;
      case 3:
        this.setTitle(this.$t('general.createBrandGroup'));
        if (masterCategory.level > 3) {
          hasExecuteSetParent = true;
        }
        break;
    }

    if (hasExecuteSetParent) {
      this.setParent(parent);
    }

    if (currentLevel === masterCategory.level) {
      this.setTitle(this.$t('general.noSubLevels'));
    }
  }

  setTitle(title: any) {
    this.title = title;
  }
  setParent(data: any) {
    this.form.parent = data;
    this.form.isLiquor = this.form.parent.isLiquor;
    this.form.level = data.level;
  }

  cleanGroup() {
    this.form.parent = '';
    this.form.level = null;
    this.isEditing = false;
    this.setTitle(this.$t('general.createGroup'));
  }

  adelantar() {
    this.tabIndex++;
    this.$refs.childComponent.nextTab();
    this.tabIndex++;
    this.$refs.childComponent.nextTab();
  }

  async parentsquery() {
    this.componentKey = 2;
    await this.groupReferenceFindAllTree
      .execute()
      .then((response: any) => {
        if ('message' in response || 'error' in response) {
          this.parents = [];
          return;
        }
        this.parents = response.length > 0 ? response : [];
        this.sortArray(this.parents);
        this.isShow = true;
      })
      .catch(err => {
        this.isShow = true;
        throw new Error(`${err}`);
      });
  }

  async newGroupReference() {
    try {
      this.isLoading = true;
      const payload: any = {
        code: this.form.newCode,
        level: this.form.level,
        description: this.form.description,
        shortName: this.form.shortName,
        parent: this.form.parent.id || 0,
        stateDisable: this.form.stateDisable,
        isLiquor: this.form.isLiquor,
        isServices: this.form.isServices
      };

      await this.createGroupReference.execute(payload).then(() => {
        this.clearForm();
        this.parentsquery();
      });

      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  clearForm() {
    this.form.newCode = '';
    this.form.level = null;
    this.form.description = '';
    this.form.shortName = '';
    this.form.parent = {};
    this.form.stateDisable = false;
    this.form.isLiquor = false;
    this.$refs.observer.reset();
    this.isDisabledRadio = false;
    this.form.supplier = null;
    this.enableSupplier = false;
    this.isEditing = false;
    this.editOnlyDescription = false;
  }

  async validateForEdit(obj: any) {
    this.isLoading = true;

    if (this.lastEditing) {
      this.lastEditing.isEditing = false;
    }

    obj.isEditing = true;
    await this.findByIdGroupReference
      .execute(obj.id)
      .then((response: any) => {
        if ('message' in response || 'error' in response) {
          this.isLoading = false;
          return;
        } else {
          if (response.referencesList.length > 0 || obj.subGroup.length > 0) {
            this.editOnlyDescription = true;
          } else {
            this.editOnlyDescription = false;
          }
          this.isEditing = true;
          this.lastEditing = obj;
          this.form = Object.assign({}, response);
          this.form.newCode = response.code;
          this.isLoading = false;
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  async editGroupReference() {
    this.isLoading = true;
    if (this.isEditing) {
      await this.updateGroupReference
        .execute(this.form)
        .then((response: any) => {
          if ('message' in response || 'error' in response) {
            this.isLoading = false;
            return;
          }
          this.clearForm();
          this.parentsquery();
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  validateForDelete(obj: any) {
    //verify references asociate
    this.findByIdGroupReference
      .execute(obj.id)
      .then((response: any) => {
        if (response.referencesList.length > 0) {
          showAlert('error', 'Category ' + obj.code + ' has associated references');
        } else {
          this.deleteCategory(obj);
        }
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }

  deleteCategory(obj: any) {
    this.groupReferenceDelete
      .execute(obj)
      .then((response: any) => {
        if (!response.code) {
          showAlert('error', 'Category ' + obj.code + ' error removed');
        } else {
          this.parentsquery();
        }
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }

  asyncFind(query: string) {
    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER']
      };
      this.isLoading = true;
      this.customerFindFilter
        .execute(params)
        .then((response: any) => {
          this.suppliers = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  async findAllSuppliers() {
    this.isLoading = true;
    const res = await this.findSuppliers.execute();
    if (res.length > 0) {
      this.suppliers = res.filter(item => item.typeCustomer.type == 'SUPPLIER');
    }
    this.isLoading = false;
  }

  setValue(supplier: Entity) {
    this.form.newCode = supplier.prefix != undefined ? supplier.prefix + supplier.code : supplier.code;
    this.form.description = supplier.fullName.trim();
    this.$refs.suppliersModal.hide();
  }
  setValueEmpty() {
    this.form.newCode = '';
    this.form.description = '';
  }

  sortArray(array: any) {
    array.sort((a: any, b: any) => (a.description > b.description ? 1 : -1));
    array.forEach((element: any) => {
      if (element.subGroup.length > 0) {
        this.sortArray(element.subGroup);
      }
    });
  }
}
